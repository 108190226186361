.filters-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-height: calc(100vh - 15rem);
    overflow: auto;
    padding-right: 0.5rem;
}
.pips {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1.5rem 0;
}
.pips .pip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1.2rem;
    position: relative;
    cursor: pointer;
}
.pips .pip:not(:last-child) {
    margin-right: 1.5rem;
}
.pips .pip:not(:first-child):before {
    content: '';
    position: absolute;
    top: 0.25rem;
    left: -1.75rem;
    width: 2rem;
    height: 0.5rem;
    background: #eee;
    z-index: 0;
    transition: background .3s ease-out;
    will-change: background;
}
.pips .pip.active:not(:first-child):before,
.pips .pip.passed:not(:first-child):before {
    background: #2A59FF;
}
.pips .pip .circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    background: #fff;
    border-radius: 50%;
    border: 2px solid #bdbdbd;
    margin-bottom: .5rem;
    z-index: 1;
    transition: border .3s ease-out, transform .3s ease-out;
    will-change: border, transform;
}
.pips .pip.active .circle {
    transform: scale(1.25);
    border: 2px solid #2A59FF;
}
.pips .pip.passed .circle {
    border: 2px solid #2A59FF;
}
.pips .pip:not(.active):hover .circle {
    transform: scale(1.05)
}
.pips .pip .label {
    color: #323232;
    font-size: .75rem;
    font-weight: 400;
    margin: 0;
    transition: font-weight .3s ease-out;
    will-change: font-weight;
}
.pips .pip.active .label {
    font-weight: 600;
}
.range-wrapper {
    position: relative;
}
.range-title {
    font-size: .85rem;
    color: #bdbdbd;
    position: relative;
    top: -1.5rem;
}
.filters-area .sort-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.filters-area .sort-field {
    max-width: 100%;
}
.filters-area .sort-area .sort-field:not(:last-child),
.filters-area .sort-area .sort-field {
    margin-right: 0;
    margin-bottom: 1rem;
    width: 100%;
}
.filters-area .sort-area .range-inputs .sort-field {
    margin-bottom: .5rem;
}

.filters-area .sort-area .ranges {
    width: 100%;
    margin-top: .5rem;
}

.filters-area .sort-area .ranges .range-inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 0
}

.filters-area .sort-area .ranges .error-hint {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    color: #cc0000;
    font-size: .75rem;
    font-weight: 500;
}

.filters-area .sort-area .ranges .range-inputs .sort-field .m-field-icon {
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
    z-index: 2;
}
.filters-area .sort-area .ranges .range-inputs .m-notch-label.active {
    top: 0.15rem;
    font-size: .75rem;
}
.filters-area .sort-area .ranges .range-inputs .sort-field:not(:last-child) {
    margin-right: 1rem;
}

.filters-area .sort-area .ranges .range-inputs input {
    background: #f5f5f5;
    padding: .5rem 1rem .5rem 1.75rem;
    border-radius: 2rem;
    color: #323232;
    outline: none;
    max-width: 15rem;
    transition: background .3s ease-out;
    will-change: background;
}

.filters-area .sort-area .ranges .range-inputs span {
    margin: 0 1rem
}

.filters-area .sort-area .ranges .range-inputs input::placeholder {
    color: #757575
}

.filters-area .sort-area .ranges .range-inputs input:hover,
.filters-area .sort-area .ranges .range-inputs input:active {
    background: #e9e9e9;
}

@media (max-width: 480px) {
    .pips {
        justify-content: flex-start;
        overflow: auto;
        padding-bottom: 1rem;
        padding-top: 0.5rem;
        margin: 1rem 0 0;
    }
}
@media (max-width: 768px) {
    .filters-area .sort-area {
        margin: 1rem 0;
    }
    .filters-area .sort-area.m-0 {
        margin: 0;
    }
    .range-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        top: 0;
        margin-bottom: 1rem;
        color: #757575;
        font-weight: 500;
    }

    .filters-area .sort-area .ranges .range-inputs span {
        margin: .5rem 1rem
    }
    .filters-area .sort-area .ranges .range-inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }
    .filters-area .sort-area .ranges .range-inputs input {
        display: flex;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 1.75rem;
        padding-right: 1rem;
        background: transparent;
        font-weight: 400;
        letter-spacing: .009375em;
        text-decoration: inherit;
        text-transform: inherit;
        align-self: flex-end;
        box-sizing: border-box;
        box-shadow: none;
        font-size: 1rem;
        height: 2.25em;
        justify-content: flex-start;
        line-height: 1.5;
        position: relative;
        border-radius: 0.25rem;
        z-index: 1;
        appearance: none;
        max-width: none;
        transition: focus .15s cubic-bezier(0.4, 0, 0.2, 1);
        will-change: focus;
    }
    .filters-area .sort-area .ranges .range-inputs input:focus,
    .filters-area .sort-area .ranges .range-inputs input:active {
        outline: none;
        border: 1px solid #4285f4;
    }
    .filters-area .sort-area .ranges {
        border-bottom: 1px solid #e0e0e0;
        margin-bottom: 1rem;
    }
    .buttons-area {
        margin-top: 2rem;
        flex-wrap: wrap;
    }
    .filters-area .sort-area .ranges .range-inputs input:hover,
    .filters-area .sort-area .ranges .range-inputs input:active {
        background: transparent;
    }
    .filters-area .sort-area .range-inputs .sort-field:not(:last-child),
    .filters-area .sort-area .range-inputs .sort-field { 
        width: calc(50% - 1rem);
        margin: 0;
    }
    .filters-area .sort-area .sort-field.m-field-select:not(:last-child) {
        margin-bottom: 2rem;
    }
}
.filter {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    z-index: 99;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 40px 10px;
    overflow: auto;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity ease-in-out 0.3s;
    transition: opacity ease-in-out 0.3s;
  }
  .filter__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 12px 18px 12px;
    width: 300px;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
    -webkit-transition: opacity ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
    transition: opacity ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
    transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s;
    transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  }
  .filter__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 8px;
    width: 100%;
  }
  .filter__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 8px;
  }
  .filter__button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 3px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 40px;
    padding: 0 25px;
    font-family: Rubik;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .filter__button_filled {
    border-radius: 6px;
    background-color: #2A59FF;
    color: #fff;
    -webkit-transition: background-color ease-in-out 0.3s;
    transition: background-color ease-in-out 0.3s;
  }
  .filter__button_filled:hover {
    background-color: #1039cc;
  }
  .filter__button_filled.danger {
    background-color: #DD0000;
  }
  .filter__button_filled.danger:hover {
    background-color: #ee0000;
  }
  .filter__button_stroke {
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
    -webkit-transition: background-color ease-in-out 0.3s;
    transition: background-color ease-in-out 0.3s;
  }
  .filter__button_stroke:hover {
    background-color: rgba(204, 204, 204, 0.062745098);
  }
  .filter_popup {
    z-index: 9999;
  }
  
  .filter-range {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .filter-range__title {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #55606F;
  }
  .filter-range__inputs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 7px;
  }
  .filter-range__input-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 134px;
    border-radius: 6px;
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }
  .filter-range__input {
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-width: none;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    background-color: transparent;
    padding-left: 10px;
  }
  .filter-range__icon {
    margin: 0 10px;
    color: #2A59FF;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  .filter-range__range {
    margin-top: 8px;
    width: 100%;
  }
  .filter-range__labels {
    margin-top: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
  }
  .filter-range__label {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #55606F;
  }
  
  .filter._opened {
    opacity: 1;
    width: 100vw;
    height: 100vh;
    pointer-events: all;
  }
  .filter._opened .filter__wrapper {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  .filter._opened .select.is-active .select__body {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
    pointer-events: all;
  }
  .filter._opened .select.is-active .select__icon i {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }