.modal-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 100%;
  left: 0;
  z-index: 113;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease-out, transform .3s ease-out;
  will-change: opacity, transform;
}

.modal-area:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05)
}
.dark .modal-area:before {
  background: rgba(255, 255, 255, 0.05)
}

.modal-area.active {
  top: 0;
  opacity: 1;
  visibility: visible;
  backdrop-filter: blur(3px);
}

.modal-area .modal {
  width: 90%;
  max-width: 450px;
  min-height: 250px;
  background: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.account .modal-area .modal {
  top: 0;
  transform: none;
}
.modal-area .modal.visible {
  overflow: visible;
}
.modal-area .modal.inner-modal {
  top: 0;
  transform: none;
}
.modal-area .modal.wide {
  max-width: 550px;
}
.dark .modal-area .modal {
  background: #141827
}

.modal-area.wide .modal {
  max-width: 650px;
}

.modal-area .modal .close-modal {
  position: absolute;
  top: .75rem;
  right: 1rem;
  font-size: 2rem;
  width: 2rem;
  height: 2rem;
  transition: color .3s ease-out;
  will-change: color;
  cursor: pointer;
  z-index: 2;
}

.modal-area .modal .close-modal {
  color: #bdbdbd;
}
.dark .modal-area .modal .close-modal {
  color: #757575;
}

.modal-area .modal .close-modal:hover,
.modal-area .modal .close-modal:active {
  color: #323232;
}

.dark .modal-area .modal .close-modal:hover,
.dark .modal-area .modal .close-modal:active {
  color: #bdbdbd;
}

.modal-area .modal .modal-content .modal-subheader {
  color: #616161;
}

.modal-area .modal .modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
}

.modal-area .modal .modal-content .modal-header {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
}

.modal-area .modal .modal-content .modal-header.leave-header {
  margin-bottom: 2rem;
}

.modal-area .modal .modal-content .modal-subheader {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  text-align: center;
  opacity: .75;
  width: 80%;
  margin: 0 auto 1rem;
}

.modal-area .modal .modal-content.flex-start {
  align-items: flex-start;
}

.modal-area .modal .modal-content.flex-start .modal-subheader {
  text-align: left;
  margin: 0 0 3rem;
}

.modal-area .content-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.modal-area .m-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  border-radius: .25rem;
}

.modal-area .m-field:not(:last-child) {
  margin-bottom: 1rem;
}

.modal-area .m-field-input {
  display: flex;
  align-items: center;
  background-color: transparent;
  z-index: 1;
  line-height: 1;
  padding: calc(0.375em - 1px) calc(0.625em - 1px) calc(0.375em - 1px) calc(0.625em - 1px);
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: .009375em;
  text-decoration: inherit;
  text-transform: inherit;
  align-self: flex-end;
  box-sizing: border-box;
  box-shadow: none;
  height: 2.5rem;
  width: 100%;
  justify-content: flex-start;
  position: relative;
  border: 1px solid rgba(0, 0, 0, .3);
  border-radius: .25rem;
  appearance: none;
}
.dark .modal-area .m-field-input {
  border: 1px solid rgba(255, 255, 255, .3);
}
.modal-area .m-field .m-down-icon {
  position: absolute;
  top: 10px;
  right: .5rem;
  width: 1rem;
  height: 1rem;
  opacity: .3;
  transition: transform .2s ease-out;
  will-change: transform;
}

.modal-area .m-field-input:focus~.m-down-icon,
.modal-area .m-field-input:active+.m-down-icon {
  transform: rotate(180deg);
}

.modal-area textarea.m-field-input {
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: auto;
  resize: vertical;
  max-height: 15rem;
}

.modal-area .m-field-input:hover {
  border: 1px solid rgba(0, 0, 0, .6);
}
.dark .modal-area .m-field-input:hover {
  border: 1px solid rgba(255, 255, 255, .6);
}

.modal-area .m-field-input:focus,
.modal-area .m-field-input:active {
  outline: none;
  border: 1px solid #4285f4;
}
.modal-area .button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.modal-area .button:not(:last-child) {
  margin-right: 1rem;
}
.modal-area .filled-button {
  min-width: 65px;
}
.modal-area .buttons-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2rem;
}
.modal-area .filter-search {
  width: 100%;
  padding: 0;
  margin-bottom: 1rem;
}
.modal-area .curerncy-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 20rem;
  overflow: auto;
  margin-bottom: 2rem;
}
.modal-area .curerncy-list::-webkit-scrollbar {
  width: 4px;
  background-color: #fff;
  border-radius: 4px;
}
.modal-area .curerncy-list::-webkit-scrollbar-thumb {
  background: #2A59FF;
  border-radius: 5px;
}
.dark .modal-area .curerncy-list::-webkit-scrollbar {
  background-color: #2A2E3A;
}
.modal-area .curerncy-list .curerncy-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}
.modal-area .curerncy-list .curerncy-item .image {
  background: #f5f5f5;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .5rem;
  border-radius: 50%;
  overflow: hidden;
  color: #9e9e9e
}
.dark .modal-area .curerncy-list .curerncy-item .image {
  background: #2c334b;
  color: #fff
}
.modal-area .curerncy-list .curerncy-item .image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.modal-area .curerncy-list .curerncy-item:not(:last-child) {
  margin-bottom: 1rem;
}
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}
.modal-container .icon-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  font-size: 4rem;
  margin-bottom: 2rem;
  z-index: 1;
}
.modal-container .icon-area.wide {
  width: 80px;
  height: 80px;
}
.modal-container .icon-area i {
  background: linear-gradient(45deg, #2A59FF 15%, #dfaaff 85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.modal-container .warning-area .icon-area {
  align-items: flex-start;
  justify-content: flex-start;
  width: 2rem;
  height: auto;
  font-size: 1rem;
  margin-bottom: 0;
}
.modal-container .warning-area .icon-area i {
  background: transparent;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
}
.modal-container .desc-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  z-index: 2;
}
.modal-container .desc-area.with-padding {
  padding: 0 2rem
}
.modal-container .desc-area .header {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1rem;
  max-width: 80%;
}
.modal-container .desc-area p.desc {
  font-size: 1rem;
  text-align: center;
  font-weight: 400;
  line-height: 1.25;
  margin: 0;
  opacity: .75;
}
.modal.wide .modal-container .desc-area p.desc {
  max-width: 80%;
}
.modal-container .warning-area .text-area p.desc {
  font-size: .9rem;
  font-weight: 400;
  text-align: start;
  line-height: 1.25;
  opacity: 1
}
.modal-container .button-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.modal .modal-content .header-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 2rem;
}
.modal .modal-content .header-area .icon-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 4rem;
  height: 4rem;
  margin-right: .5rem;
  flex-shrink: 0;
}
.modal .modal-content .header-area .icon-area img {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
}
.modal .modal-content .header-area .text-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.modal .modal-content .header-area .text-area .h4 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: .25rem;
  text-transform: uppercase;
}
.modal .modal-content .header-area .text-area .desc {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  opacity: .5;
}
.modal .modal-content .buy-form-area {
  display: grid;
  grid-template-columns: 1fr 2.5rem 1fr;
  grid-column-gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
}
.modal .modal-content .buy-form-area.column {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  width: 100%;
  margin-bottom: 2rem;
}
.modal .modal-content .buy-form-area .input-area.with-select,
.modal .modal-content .buy-form-area .input-area.with-select .m-field {
  z-index: 2;
}
.modal .modal-content .buy-form-area .input-area .hint {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: .75rem;
  opacity: .5;
  font-weight: 600;
  margin-top: 0.5rem;
}
.modal .modal-content .buy-form-area.column .input-area .input {
  border-radius: 0.25rem;
}
.modal .modal-content .buy-form-area .m-field {
  margin-bottom: 0;
}
.modal .modal-content .buy-form-area .m-field::v-deep .coin-select {
  width: 100%;
  height: 39px;
}
.modal .modal-content .buy-form-area .m-field::v-deep .coin-select .vs__dropdown-toggle {
  height: 39px;
}
.modal .modal-content .send-form-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 0;
}
.modal .modal-content .buy-form-area .input-area,
.modal .modal-content .send-form-area .input-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}
.modal .modal-content .send-form-area .input-area {
  align-items: flex-start;
}
.modal .modal-content .send-form-area .input-area:not(:last-child) {
  margin-bottom: 2rem;
}
.modal .modal-content .buy-form-area .input-area.equals {
  justify-content: flex-end;
  margin-bottom: 0.4rem;
}
.modal .modal-content .buy-form-area .input-area.equals img {
  pointer-events: none;
}
.modal .modal-content .buy-form-area .input-area .label,
.modal .modal-content .send-form-area .input-area .label {
  font-size: .85rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.modal .modal-content .buy-form-area .input-area .input,
.modal .modal-content .send-form-area .input-area .input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #bec0c0;
  border-radius: 5rem;
}
.modal .modal-content .buy-form-area .input-area .input input {
  width: 100%;
  text-align: center;
}
.modal .modal-content .send-form-area .input-area .input input {
  width: 100%;
}
.modal .modal-content .buy-form-area .input-area .balance,
.modal .modal-content .send-form-area .input-area .balance {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -2rem;
  right: 1rem;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1;
  opacity: .5;
}
.modal .modal-content .send-form-area .input-area .balance {
  bottom: -1.5rem;
}
.modal .modal-content .apply-button,
.modal .modal-container .apply-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 2.5rem;
  border-radius: 5rem;
  background-color: #2A59FF;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  transition: background-color ease-in-out 0.3s;
  will-change: background-color;
}
.modal .modal-content .apply-button:disabled,
.modal .modal-container .apply-button:disabled {
  cursor: default;
  background-color: #879ce5;
  color: #fff;
}
.modal .modal-content .apply-button:not(:disabled):hover,
.modal .modal-content .apply-button:not(:disabled):active,
.modal .modal-container .apply-button:not(:disabled):hover,
.modal .modal-container .apply-button:not(:disabled):active {
  background-color: #1039cc;
}
.modal .modal-content .apply-button .button__icon,
.modal .modal-container .apply-button .button__icon {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 0.25rem;
}
.modal .modal-content .button-area,
.modal .modal-container .button-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.modal .modal-container .button-area {
  margin-bottom: 2rem;
}
.modal .modal-content .warning-area,
.modal .modal-content .info-area {
  align-items: center;
  padding: .75rem;
}
.balance_area {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  margin: 0 0 1rem;
}
.balance_area .desc {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
  margin-right: 0.25rem;
  opacity: .75;
}
.balance_area .value {
  font-size: 1.15rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}
.cta-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem
}
.cta-area .more_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  padding: .5rem;
  border-radius: .5rem;
  border: 1px solid #eee
}
.cta-area .download_desc {
  font-size: .85rem;
  font-weight: 300;
  text-align: center;
  line-height: 1.25;
  margin-bottom: 0.5rem;
  opacity: .75;
}
.warning-area {
  padding: 0.75rem;
  margin-top: 1rem;
  margin-bottom: 0;
}
.buy_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media (max-width: 767.98px) {
    .buy_area {
        margin-bottom: 2rem;
    }
}
.modal-content .info_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(69, 78, 83, 0.2);
  background: rgba(55, 59, 62, 0.03);
  margin-bottom: .5rem;
}
.modal-content .info_row .label {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
}
.modal-content .info_row .value {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
}
.modal-content .info_row .value.up {
  color: #71BA57
}
.modal-content .blockchain_balance {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: .85rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 1rem;
  opacity: .75;
}
.modal-content .blockchain_balance span {
  font-weight: 600;
  margin-left: .25rem;
}
